import {ReactText} from 'react';
import assign from 'lodash/assign';
import i18next, {i18n, InitOptions, Resource, ResourceLanguage, TranslationFunction} from 'i18next';

// Translation strings
import englishTranslations from '../../../i18n/en-us.i18n.json';
import hebrewTranslations from '../../../i18n/he-il.i18n.json';
import arabicTranslations from '../../../i18n/ar-ar.i18n.json';
import chineseTranslations from '../../../i18n/zh-cn.i18n.json';
import russianTranslations from '../../../i18n/ru-ru.i18n.json';
import greekTranslations from '../../../i18n/el-gr.i18n.json';
import spanishTranslations from '../../../i18n/es-es.i18n.json';
import dutchTranslations from '../../../i18n/nl-nl.i18n.json';
import frenchTranslations from '../../../i18n/fr-fr.i18n.json';
import italianTranslations from '../../../i18n/it-it.i18n.json';
import portugueseTranslations from '../../../i18n/pt-pt.i18n.json';
import japaneseTranslations from '../../../i18n/jp-jp.i18n.json';
import turkishTranslations from '../../../i18n/tr-tr.i18n.json';
import swedishTranslations from '../../../i18n/sv-se.i18n.json';
import polishTranslations from '../../../i18n/pl-pl.i18n.json';
import germanTranslations from '../../../i18n/de-de.i18n.json';
import koreanTranslations from '../../../i18n/ko-kr.i18n.json';
import slovakTranslations from '../../../i18n/sk-sk.i18n.json';
import thaiTranslations from '../../../i18n/th-th.i18n.json';
import hungarianTranslations from '../../../i18n/hu-hu.i18n.json';
import lithuanianTranslations from '../../../i18n/lt-lt.i18n.json';
import albanianTranslations from '../../../i18n/sq-al.i18n.json';
import czechTranslations from '../../../i18n/cz-cz.i18n.json';
import canadianFrenchTranslations from '../../../i18n/fr-ca.i18n.json';
import danishTranslations from '../../../i18n/da-dk.i18n.json';
import norwegianTranslations from '../../../i18n/nv-no.i18n.json';
import finnishTranslations from '../../../i18n/fi-fi.i18n.json';
import bulgarianTranslations from '../../../i18n/bg-bg.i18n.json';
import croatianTranslations from '../../../i18n/hr-hr.i18n.json';
import romanianTranslations from '../../../i18n/ro-ro.i18n.json';
import slovenianTranslations from '../../../i18n/sl-si.i18n.json';
import ukrainianTranslations from '../../../i18n/uk-ua.i18n.json';
import indonesianTranslations from '../../../i18n/id-id.i18n.json';
import vietnameseTranslations from '../../../i18n/vi-vn.i18n.json';

import {IDbCustomStrings} from './AngularServices/AngularServices';

import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import includes from 'lodash/includes';

const RTL_LANGUAGES = ['he_IL'];

export interface ITranslate {
    (token: string, data?: {[key: string]: ReactText}): string;
}

export interface ILanguageChanger {
    (language: string): PromiseLike<void>;
}

export interface ILocalizationService {
    translate: ITranslate;
    changeLanguage: ILanguageChanger;

    init(): Promise<void>;
    setAccountData(accountId: string, language: string): void;
    getTextDirection(): string;
    isInitialized(): boolean;
}

export class LocalizationService implements ILocalizationService {
    private _i18nextInstance: i18n;

    private _i18nextTranslator?: TranslationFunction;

    private _accountId: string = '';

    private _currentLanguage: string = 'en_US';

    private _customStringsService: IDbCustomStrings;

    constructor(customStringsService: IDbCustomStrings) {
        this._customStringsService = customStringsService;
        this._i18nextInstance = i18next.createInstance();

        this.translate = this.translate.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    async init(): Promise<void> {
        return this.getCustomStrings().then((resp: {data: ResourceLanguage}) => {
            const preloadedResources = this.getPreloadedLocales(resp.data);
            const translationOptions = this.getTranslationOptions(preloadedResources, this._currentLanguage);

            // eslint-disable-next-line handle-callback-err
            this._i18nextInstance.init(translationOptions, (err: any, t: TranslationFunction) => {
                this._i18nextTranslator = t;
            });
        });
    }

    setAccountData(accountId: string, language: string) {
        this._accountId = accountId;
        this._currentLanguage = language;
    }

    isRTL() {
        return includes(RTL_LANGUAGES, this._currentLanguage);
    }

    getTextDirection() {
        return this.isRTL() ? 'rtl' : 'ltr';
    }

    translate(token: string, data?: {[key: string]: ReactText}): string {
        if (!this._i18nextTranslator) {
            throw new Error('translate cannot be called until service is ready.');
        }

        const translatedString = this._i18nextTranslator(token, data as any);
        //translatedString = data ? replaceTokens(translatedString, data) : translatedString;

        return translatedString;
    }

    changeLanguage(language: string): PromiseLike<void> {
        this._i18nextInstance.changeLanguage(language);

        return Promise.resolve();
    }

    isInitialized() {
        return this._i18nextInstance?.isInitialized;
    }

    private getCustomStrings() {
        if (!this._accountId) {
            return Promise.resolve({data: {}});
        }

        return this._customStringsService.byAccount({
            params: {
                accountId: this._accountId,
                platformType: PlatformType.dashboard
            }
        });
    }

    private getTranslationOptions(resources: Resource, initialLanguage: string): InitOptions {
        return {
            lng: initialLanguage,
            debug: false,
            //Settings keySeparator to false, allows to use . symbol in translation keys.
            keySeparator: false,
            resources: resources
        };
    }

    private getPreloadedLocales(customStrings: ResourceLanguage): Resource {
        const locales: Resource = {};
        const addLocale = (locale: string, data: any) => {
            locales[locale] = {translation: assign(data, customStrings)};
        };

        addLocale('en_US', englishTranslations);
        addLocale('he_IL', hebrewTranslations);
        addLocale('ar_AR', arabicTranslations);
        addLocale('ru_RU', russianTranslations);
        addLocale('el_GR', greekTranslations);
        addLocale('es_ES', spanishTranslations);
        addLocale('nl_NL', dutchTranslations);
        addLocale('fr_FR', frenchTranslations);
        addLocale('it_IT', italianTranslations);
        addLocale('pt_PT', portugueseTranslations);
        addLocale('jp_JP', japaneseTranslations);
        addLocale('tr_TR', turkishTranslations);
        addLocale('sv_SE', swedishTranslations);
        addLocale('pl_PL', polishTranslations);
        addLocale('de_DE', germanTranslations);
        addLocale('ko_KR', koreanTranslations);
        addLocale('sk_SK', slovakTranslations);
        addLocale('th_TH', thaiTranslations);
        addLocale('hu_HU', hungarianTranslations);
        addLocale('lt_LT', lithuanianTranslations);
        addLocale('sq_AL', albanianTranslations);
        addLocale('cz_CZ', czechTranslations);
        addLocale('fr_CA', canadianFrenchTranslations);
        addLocale('da_DK', danishTranslations);
        addLocale('nv_NO', norwegianTranslations);
        addLocale('fi_FI', finnishTranslations);
        addLocale('bg_BG', bulgarianTranslations);
        addLocale('hr_HR', croatianTranslations);
        addLocale('ro_RO', romanianTranslations);
        addLocale('sl_SI', slovenianTranslations);
        addLocale('uk_UA', ukrainianTranslations);
        addLocale('id_ID', indonesianTranslations);
        addLocale('vi_VN', vietnameseTranslations);
        addLocale('zh_CN', chineseTranslations);

        return locales;
    }
}
