import {action, computed, observable} from 'mobx';
import {ITranslate} from '../../services/LocalizationService';
import moment from 'moment';
import {getRootStore} from '../../app.bootstrap';
import {SESSION_SOURCE} from '@techsee/techsee-common/lib/constants/room.constants';
import InteractionSummaryEventService from '@techsee/techsee-ui-components/lib/interaction-summary/eventService';

const PAGE_SIZE = 5;

export interface VjSession {
    creationTimestamp: string;
    customerId: string;
    endTimestamp: string;
    sessionId: string;
    sessionSource: string;
    visualJourneyTotalImagesUploaded: number;
    flowName: string;
    totalResults: number;
}

export interface interactionSummaryTranslation {
    collapseAll: string;
    expandAll: string;
    noData: string;
    unknownError: string;
    refresh: string;
    textAnalysis: string;
    SELECT_FLOW: string;
    CAPTURE: string;
    ADD_IMAGE: string;
    RECORD_AUDIO: string;
    TEXT_INPUT: string;
    API_CALL: string;
    SCAN: string;
    TEXT_PROCESSOR: string;
    IMAGE_ANALYSIS: string;
    SET_KEY_VALUE: string;
    FORM: string;
    SPEED_TEST: string;
}

export interface IVjHistoryController {
    dateFormat(date: string, format: string): string;
    selectSession(session: any): void;
    nextPage(): void;
    prevPage(): void;
    goToInvitePage?: () => void;
    getInteractionSummaryData: () => any;
    getDateFormattedByAcc: (date: string, withHours?: boolean) => string;
    vjGetImage: (img: string, label?: string, quality?: string) => void;
    closeModal: () => void;
    searchHistory: () => Promise<void>;
    updateVjList: (newList: VjSession[]) => void;

    readonly translate: ITranslate;
    readonly vjList: any[];
    readonly endDate: string;
    readonly startDate: string;
    readonly customerRef: string;
    readonly selectedSession: VjSession;
    readonly currentPage: number;
    readonly totalPages: number;
    readonly isNextAvailable: boolean;
    readonly isPrevAvailable: boolean;
    readonly isWidgetOpen: boolean;
    readonly getDataOnInitialLoad: boolean;
    readonly isInteractionSummaryReady: boolean;
    readonly openModal: boolean;
    readonly selectedImg: any;
    readonly interactionSummaryTranslated: interactionSummaryTranslation;
}

type SearchCriteria = {
    startDate?: string;
    endDate?: string;
    customerRef?: string;
    roomId?: string;
};

export class VjHistoryController implements IVjHistoryController {
    private readonly _translate: ITranslate;
    private readonly _startDate: string;
    private readonly _endDate: string;
    private readonly _customerRef: string;
    private readonly _roomId: string;
    private accountId: string;

    @observable private _vjList: any = [] as any as VjSession[];
    @observable private _selectedSession: any = {} as any as VjSession;
    @observable private _totalPages: number = 1;
    @observable private _currentPage: number = 1;
    @observable private _isWidgetOpen: boolean = false;
    @observable private _getDataOnInitialLoad: boolean = false;
    @observable private readonly _accDateFormat: string = '';
    @observable private readonly _timeZone: string = '';
    @observable private readonly _language: string = '';
    @observable private _openModal: boolean = false;
    @observable private _selectedImg: any = {} as any;

    readonly goToInvitePage?: () => void;

    constructor(
        translate: ITranslate,
        accountId: string,
        accDateFormat: string,
        timeZone: string,
        language: string,
        searchCriteria: SearchCriteria,
        goToInvitePage?: () => void
    ) {
        this.selectSession = this.selectSession.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.getInteractionSummaryData = this.getInteractionSummaryData.bind(this);
        this.getDateFormattedByAcc = this.getDateFormattedByAcc.bind(this);
        this.vjGetImage = this.vjGetImage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this._translate = translate;
        this.goToInvitePage = goToInvitePage;
        this._startDate = searchCriteria.startDate ? this.dateFormat(searchCriteria.startDate, 'MM/DD/YYYY') : '';
        this._endDate = searchCriteria.endDate ? this.dateFormat(searchCriteria.endDate, 'MM/DD/YYYY') : '';
        this._customerRef = searchCriteria.customerRef ?? '';
        this._roomId = searchCriteria.roomId ?? '';
        this.accountId = accountId;
        this._accDateFormat = accDateFormat;
        this._timeZone = timeZone;
        this._language = language.replace('_', '-');
    }

    get translate() {
        return this._translate;
    }

    @computed
    get vjList() {
        return this._vjList;
    }

    @computed
    get startDate() {
        return this._startDate;
    }

    @computed
    get endDate() {
        return this._endDate;
    }

    @computed
    get customerRef() {
        return this._customerRef;
    }

    @computed
    get selectedSession() {
        return this._selectedSession;
    }

    @computed
    get isInteractionSummaryReady() {
        return this._selectedSession.sessionId;
    }

    @computed
    get getDataOnInitialLoad() {
        return this._getDataOnInitialLoad;
    }

    @computed
    get isWidgetOpen() {
        return this._isWidgetOpen;
    }

    @computed
    get isNextAvailable() {
        return this.currentPage < this._totalPages;
    }

    @computed
    get isPrevAvailable() {
        return this._currentPage > 1;
    }

    @computed
    get totalPages() {
        return this._totalPages;
    }

    @computed
    get currentPage() {
        return this._currentPage;
    }

    @computed
    get interactionSummaryTranslated() {
        return getRootStore().interactionSummaryTranslated;
    }

    @computed
    get selectedImg() {
        return this._selectedImg;
    }

    @computed
    get openModal() {
        return this._openModal;
    }

    @computed
    get roomId() {
        return this._roomId;
    }

    @action
    selectSession(session: VjSession) {
        this._selectedSession = session;
        this._isWidgetOpen = true;
        this._getDataOnInitialLoad = true;
        InteractionSummaryEventService.toggleWidget({sessionId: session.sessionId || ''});
    }

    @action
    nextPage() {
        this._currentPage++;
        this.searchHistory();
    }

    @action
    prevPage() {
        this._currentPage--;
        this.searchHistory();
    }

    @action
    dateFormat(date: string, format: string) {
        return moment(date).format(format);
    }
    @action
    public async searchHistory(): Promise<void> {
        const pageNumber = this._currentPage;
        const startDateValue = this.startDate ? new Date(this.startDate) : undefined;
        const endDateValue = this.endDate ? new Date(this.endDate) : new Date();
        const customerRef = this.customerRef || undefined;
        const roomId = this.roomId || undefined;

        startDateValue?.setHours(0, 0, 0, 0);
        endDateValue.setHours(23, 59, 59, 999);

        if (startDateValue || customerRef || roomId) {
            await getRootStore()
                .interactionSummaryService.listInteractionSummaries(
                    this.accountId,
                    customerRef,
                    startDateValue,
                    endDateValue,
                    PAGE_SIZE,
                    pageNumber - 1,
                    SESSION_SOURCE.VJ,
                    {
                        roomId
                    }
                )
                .then((res) => {
                    getRootStore().safeApply(() => {
                        this.updateVjList(res.previews);

                        if ((roomId || customerRef) && res.previews.length === 1) {
                            this.selectSession(res.previews[0]);
                        }
                    });
                });
        }
    }
    @action
    public updateVjList(newList: VjSession[]): void {
        this._totalPages = Math.ceil((newList[0]?.totalResults || 1) / PAGE_SIZE);
        this._vjList = newList;
    }

    @action
    async getInteractionSummaryData() {
        const roomId = this._selectedSession?.sessionId;

        const interactionSummaryResults = await getRootStore().interactionSummaryService.getInteractionSummary(
            roomId,
            undefined,
            undefined
        );

        interactionSummaryResults.sessionSource = this.translate('REACT.INTERACTION.SUMMARY.INTERACTION.SUMMARY');

        return interactionSummaryResults;
    }

    @action
    getDateFormattedByAcc(date: string, withHours: boolean = true) {
        const fixedDate = withHours
            ? new Date(date).toLocaleString(this._language, {
                  timeZone: this._timeZone
              })
            : date;

        const format: string = withHours ? this._accDateFormat : this._accDateFormat.split(' ')[0];

        return getRootStore().getDateFormattedByAcc(fixedDate, format);
    }

    @action
    vjGetImage(img: string, label?: string, quality?: string) {
        this._openModal = true;
        this._selectedImg = {url: img, label: label || '', quality: quality || ''};
    }

    @action
    closeModal() {
        this._openModal = false;
    }
}
